const public_layout = (function () {
  const $html = $('html')

  function init () {
    // utilities
    rollOverJaquette()
    shareBar()
    fitImageInContainer()
    openPopUp()
    initTabs()
    initLanguageSwitch()

    // call les bonnes fonctions relatives Ã  une page
    const nomTemplate = $('body').attr('id')
    if (nomTemplate !== '' && window[nomTemplate] && typeof (window[nomTemplate].init) === 'function') {
      window[nomTemplate].init()
    }

    // classe mobile ou desktop
    if (Utils.isMobile.any()) {
      $html.addClass('is-mobile')
    } else {
      $html.addClass('is-desktop')
    }

    // classe navigateur
    $html.addClass(Utils.whichBrowser())
  }

  /// /////////////////////////////////////////////
  // UTILITIES
  /// /////////////////////////////////////////////

  function rollOverJaquette () {
    $('.containerPochette, .containerScreenshot, .titre, .priceTag').on('mouseenter', function () {
      const $container = $(this).closest('.vignette,article')
      $container.addClass('is-hoverPlay')
    })

    $('.containerPochette, .containerScreenshot, .titre, .priceTag').on('mouseleave', function () {
      const $container = $(this).closest('.vignette,article')
      $container.removeClass('is-hoverPlay')
    })
  }

  function shareBar () {
    if (Utils.isMobile.any()) {
      $('.m-shareBar .shareLink').click(function () {
        $(this).parent().addClass('is-active')
        return false
      })
    } else {
      $('.m-shareBar .shareLink').hover(function () {
        $(this).parent().addClass('is-active')
      })
      $('.m-shareBar').hover(function (e) {
        if ($(e.target).is('.shareLink')) {
          $(this).addClass('is-active')
        }
      }, function () {
        $(this).removeClass('is-active')
      })
    }
  }

  function fitImageInContainer () {
    $(window).on('resize', function () {
      $('img[data-fit-image-in-container]').each(function () {
        const $image = $(this)
        const ratioW = $image.attr('data-fit-image-in-container-ratio-w')
        const ratioH = $image.attr('data-fit-image-in-container-ratio-h')
        const $parent = $('#' + $image.attr('data-fit-image-in-container'))

        Utils.fitImageInContainer($image, $parent, ratioW / ratioH)
      })
    }).trigger('resize')
  }

  function openPopUp () {
    $("[data-popUp='true']").on('click', function () {
      const width = 558
      const height = 558
      const scrollbars = true
      const link = $(this).attr('href')
      const popup = window.open(link, 'name', 'height=' + height + ', width=' + width + ', scrollbars=' + scrollbars)
      if (window.focus) {
        popup.focus()
      }
      return false
    })
  }

  function initTabs () {
    $('.m-tabs > ul li a').click(function () {
      const $bandeTabs = $(this).closest('.m-tabs')
      $bandeTabs.find('> ul li').removeClass('active')
      $(this).parent().addClass('active')
      const currentTab = $(this).attr('href')
      $('> div', $bandeTabs).hide()
      $(currentTab).show()
      $('.titreDotDotDot', $bandeTabs).trigger('update')
      return false
    })

    $('.m-tabs > ul li a').each(function () {
      if ($(this).attr('href') === window.location.hash) {
        $(this).click()
      }
    })
  }

  // Reveal public pointers to
  // private functions and properties
  return {
    init
  }

  /// /////////////////////////////////////////
  // LANGUAGE SWITCH
  /// ////////////////////////////////////////
  function initLanguageSwitch () {
    if (typeof (gtag) !== 'undefined') {
      $('#language-switch').click(function () {
        // var tracker = _gat._getTrackerByName();
        // var languageSwitch = document.getElementById('language-switch');
        const currentLanguage = $('html').attr('lang')
        const label = currentLanguage == 'fr' ? 'French to English' : 'English to French'
        gtag('event', 'language-switch', { event_category: 'UI-UX', event_label: label })
        // tracker._link(this.href);
      })
    }
  }
})()

$('document').ready(function () {
  public_layout.init()
})

// Update dot dot dot une fois la fonte loadé
document.onreadystatechange = function () {
  if (document.readyState === 'complete') {
    $('.titreDotDotDot').trigger('update')
  }
}

globalThis.public_layout = public_layout
